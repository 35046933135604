const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6935885",
    space_js: "//acjfpbd.cjdigit.com/source/wrq/z_jott_o.js"
  }
];

const Native = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6935886",
    space_js: "//acjfpbd.cjdigit.com/common/x/common/sra/kp/source/uu/openjs/r.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6935887",
    space_js: "//acjfpbd.cjdigit.com/source/yt/source/s_b/openjs/lqv/resource/vu.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native,
  Detail_banner: Banner,
  List_native: Native,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native,
  Result_banner: Banner
};
